import img1 from "../assets/images/folder1.png";
import img2 from "../assets/images/folder2.png";
import img3 from "../assets/images/folder3.png";

export const Domains = [
  {
    link: "https://drive.google.com/file/d/1H0YVEc1TPzHfh0lZDF-6osz0PNwiwEKT/view?usp=drive_link",
    name: "Web Dev",
    category: "Software Domain",
    image: img1,
  },
  {
    link: "https://drive.google.com/file/d/1LItxCx70XFtuu-ladXZrbq18yZkDfOqZ/view?usp=drive_link",
    name: "UI / UX",
    category: "Design Domain",
    image: img2,
  },
  {
    link: "https://drive.google.com/file/d/1UGYHODn8kBdV6dfrOnVrzVxUfGAxtkrp/view?usp=drive_link",
    name: "App Dev",
    category: "Software Domain",
    image: img1,
  },
  {
    link: "https://drive.google.com/file/d/1gqFsiztIqC_NTGk3QidvimcY6g6qCTh6/view?usp=drive_link",
    name: "Internet of Things",
    category: "Hardware Domain",
    image: img3,
  },
  {
    link: "https://drive.google.com/file/d/1VsJFS35RRs6Uk_4R5qWhc29fCbJGPQ0s/view?usp=drive_link",
    name: "Competitive Programming",
    category: "Software Domain",
    image: img1,
  },
  {
    link: "https://drive.google.com/file/d/1oVoXjCcibQH6IhQZSYe1rCnLPbwLn3fe/view?usp=drive_link",
    name: "Robot Operating System",
    category: "Hardware Domain",
    image: img3,
  },
  {
    link: "https://drive.google.com/file/d/19tsQ44eztR_gEUzu9tWaer1xFi5qioTo/view?usp=drive_link",
    name: "AI / ML",
    category: "Software Domain",
    image: img1,
  },
  {
    link: "https://drive.google.com/file/d/1aoxEBmuyvZCmmryir-xgOZO-L99pihaj/view?usp=drive_link",
    name: "Microcontroller And Embedded Systems",
    category: "Hardware Domain",
    image: img3,
  },
  {
    link: "https://drive.google.com/file/d/1ly5qFZZQY58YWq5qPTj7-TQsB9V_GN_t/view?usp=drive_link",
    name: "Blockchain",
    category: "Software Domain",
    image: img1,
  },
  {
    link: "https://drive.google.com/file/d/1EKmJ5FTcnq5Daz1Rl3TFS_VecAnALQG7/view?usp=drive_link",
    name: "Video Editing",
    category: "Design Domain",
    image: img2,
  },
  {
    link: "https://drive.google.com/file/d/12ILlkJ_TY7uWVYjjirTYA0O9nKlh3gmJ/view?usp=drive_link",
    name: "DevOps",
    category: "Software Domain",
    image: img1,
  },
  {
    link: "https://drive.google.com/file/d/1H8-qX3eQ_BPbm16_jFw31OQF_KCqPraV/view?usp=drive_link",
    name: "Graphic Designing",
    category: "Design Domain",
    image: img2,
  },
  {
    link: "https://drive.google.com/file/d/1V8Hr-BvPDvaudOSGt3NyokmArNaukNxh/view?usp=drive_link",
    name: "Backend",
    category: "Software Domain",
    image: img1,
  },
  {
    link: "https://drive.google.com/file/d/1Ub_8HdmEE7-KvkJKXAnABIy_JqOXU4GU/view?usp=drive_link",
    name: "Game-Dev",
    category: "Software Domain",
    image: img1,
  },
  {
    link: "https://drive.google.com/file/d/1fc0YSytXvhxkxyjMGx6lHgK0XeMYZvPx/view?usp=drive_link",
    name: "Ethical Hacking & Cyber Security",
    category: "Software Domain",
    image: img1,
  },
  {
    link: "https://drive.google.com/file/d/1xcPnwWVDLSwYhGdsuvnRtg2Kmn_KOJnM/view?usp=drive_link",
    name: "Cloud Computing",
    category: "Software Domain",
    image: img1,
  },
];
