import img1 from "../assets/images/navbar-logo.png";

const Loader = () => {
  return (
    <>
      <div id="loader">
      <img src={img1} id="loadimg" alt="navbarlogo" />
      </div>
    </>
  )
}

export default Loader
